/**
 * Variables
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section custom-properties */
:root {
	--font-headline: 'Museo Slab', 'Roboto Slab', serif;
	--font-text: system-ui;
	--color-black: hsl(0deg 0% 0%);
	--color-gray: hsl(0deg 0% 21%);
	--color-white: hsl(0deg 0% 100%);
	--color-steel: hsl(200deg 15% 85%);
	--color-beige: hsl(49deg 29% 82%);
	--color-beige-light: hsl(60deg 39% 91%);
	--color-beige-tinted: hsl(50deg 29% 84%);
	--color-beige-tinted-active: hsl(49deg 30% 85%);
	--color-beige-shaded: hsl(49deg 29% 74%);
	--color-beige-shaded-active: hsl(49deg 29% 65%);
	--color-accent: hsl(120deg 100% 20%);
	--color-highlight: hsl(45deg 100% 60%);
	--color-error: hsl(0deg 100% 40%);
	--color-link: hsl(120deg 100% 20%);
	--color-link-active: hsl(120deg 100% 27%);
	--color-gradient-start: hsl(120deg 100% 20%);
	--color-gradient-end: hsl(120deg 100% 10%);
}
