/**
 * Forms
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section form-defaults */
textarea,
select,
input {
	padding: 5px;
	border: 1px solid var(--color-black);
	border-radius: 0;
	inline-size: 100%;
	font-size: 1em;
	font-family: var(--font-text);
	accent-color: var(--color-accent);
	color: var(--color-black);
	background-color: var(--color-white);
	background-clip: padding-box; /* removes inner shadow on iOS */
	box-shadow: none; /* removes validation outline in Firefox */
	box-sizing: border-box;
}

select {
	block-size: 30px;
}

input::placeholder {
	color: hsl(from var(--color-gray) h s 50%);
}

label {
	display: block;
	font-weight: bold;
}

input[type='file'] {
	padding: 0;
	border: 0;
	background-color: transparent;
}

input[type^='datetime'] {
	appearance: textfield;
}

input[type^='datetime']::-webkit-date-and-time-value {
	text-align: start;
}

input:is([type='checkbox'], [type='radio']) {
	inline-size: auto;
	accent-color: var(--color-link);
}

input:is([type='checkbox'], [type='radio']) + label {
	display: inline; /* for labels next to checkboxes or radio boxes */
}

/* @section form-errors */
form [role='alert'] {
	font-weight: bold;
	color: var(--color-error);
}

/* @section buttons */
button,
.button:is(:link, :visited, :hover, :focus, :active) {
	position: relative;
	display: inline-block;
	margin: 0 0 0.5em;
	padding: 9px 20px;
	border: 0;
	border-radius: 20px;
	inline-size: 100%;
	color: var(--color-white);
	background-color: var(--color-gradient-end);
	background-image: linear-gradient(
		to bottom,
		var(--color-gradient-start),
		var(--color-gradient-end)
	);
	font-size: 1em;
	line-height: inherit;
	text-decoration: none;
	text-align: center;
	vertical-align: bottom;
	transition: box-shadow 0.5s ease;
	box-sizing: border-box;
	cursor: pointer;
	appearance: none;
}

button:is(:hover, :focus),
.button:is(:hover, :focus) {
	box-shadow: 0 0 0.5em hsl(from var(--color-gradient-start) h s l / 50%);
}

button:active,
.button:active {
	background-color: var(--color-gradient-start);
	background-image: linear-gradient(
		to top,
		var(--color-gradient-start),
		var(--color-gradient-end)
	);
}

/* @section secondary-buttons */
.button-secondary,
.button-secondary:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-gray);
	background-color: var(--color-beige-shaded);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

.button-secondary:is(:hover, :focus) {
	box-shadow: 0 0 0.5em hsl(from var(--color-beige) h s l / 50%);
}

.button-secondary:active {
	inset-block-start: 0;
	background-color: var(--color-beige-tinted);
	background-image: linear-gradient(
		to top,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

/* @section icon-buttons */
.button-icon::before {
	content: '';
	display: inline-block;
	margin-inline-end: 1ex;
	inline-size: 1em;
	block-size: 1em;
	vertical-align: -10%;
	background-repeat: no-repeat;
	background-size: contain;
}

.button-icon[download]::before {
	background-image: inline('img/download.svg');
}

.button-icon.button-share::before {
	inline-size: 1.5em;
	block-size: 1.5em;
	vertical-align: -30%;
	background-image: inline('img/share.svg');
}

.button-icon.button-random::before {
	inline-size: 1.5em;
	block-size: 1.5em;
	vertical-align: -30%;
	background-image: inline('img/random.svg');
}

.button-icon.button-subscribe::before {
	background-image: inline('img/calendar.svg');
}

.button-icon.button-bell-on::before {
	background-image: inline('img/bell.svg');
}

.button-icon.button-bell-off::before {
	inline-size: 1.25em;
	background-image: inline('img/bell-slash.svg');
}

/* @section search-form */
form.search {
	position: relative;
	padding: 0 100px 0 0;
}

form.search::before,
form.search::after {
	content: ' ';
	display: table;
}

form.search::after {
	clear: both;
}

.js form.search {
	padding: 0;
}

form.search .search-wrapper {
	box-sizing: border-box;
	border: 1px solid var(--color-beige-shaded);
	border-radius: 25px;
	min-block-size: 40px;
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

form.search button,
form.search .button {
	padding: 0;
	inline-size: 40px;
	block-size: 40px;
	background-color: var(--color-gradient-end);
	background-image: inline('img/search.svg'),
		linear-gradient(
			to bottom,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
	background-repeat: no-repeat, repeat-x;
	background-size: 24px, auto;
	background-position: center;
	text-indent: -1000em;
	overflow: hidden;
}

form.search button:active,
form.search .button:active {
	background-color: var(--color-gradient-start);
	background-image: inline('img/search.svg'),
		linear-gradient(
			to top,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
}

form.search .search-form {
	display: block;
	position: relative;
	padding: 5px 50px 5px 5px;
}

form.search .search-form label {
	display: none;
}

form.search .search-form input {
	padding: 0 10px;
	border: 1px solid #aaa;
	border-radius: 20px;
	block-size: 40px;
	font-size: 1.25em;
	color: var(--color-gray);
	background-color: hsl(from var(--color-beige) h s 98%);
	box-shadow: inset 0 2px 1px
		hsl(from var(--color-beige) h s calc(l - 25) / 50%);
	appearance: none;
}

form.search .search-form input:focus {
	border: 1px solid #060;
	outline: 0;
	box-shadow: inset 0 2px 1px hsl(from #090 h s l / 25%);
}

form.search .search-form button {
	position: absolute;
	inset-block-start: 5px;
	inset-inline-end: 5px;
}

.js form.search .search-form button {
	background-image: inline('img/random.svg'),
		linear-gradient(
			to bottom,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
	background-size: 30px, auto;
	background-position:
		25% 50%,
		50%;
}

.js form.search .search-form button:active {
	background-image: inline('img/random.svg'),
		linear-gradient(
			to top,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
}

.js form.search .search-form button.search-text {
	background-image: inline('img/search.svg'),
		linear-gradient(
			to bottom,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
	background-size: 24px, auto;
	background-position: center;
}

.js form.search .search-form button.search-text:active {
	background-image: inline('img/search.svg'),
		linear-gradient(
			to top,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
}

form.search .search-random {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	padding: 5px;
	inline-size: 52px;
}

.js form.search .search-random {
	display: none;
}

form.search .search-random .button {
	background-image: inline('img/random.svg'),
		linear-gradient(
			to bottom,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
	background-size: 30px, auto;
	background-position:
		25% 50%,
		50%;
}

form.search .search-random .button:active {
	inset-block-start: 0;
	background-image: inline('img/random.svg'),
		linear-gradient(
			to top,
			var(--color-gradient-start),
			var(--color-gradient-end)
		);
}

form.search .search-divider {
	position: absolute;
	inset-block-start: 1em;
	inset-inline-end: 52px;
	inline-size: 48px;
	text-align: center;
}

.js form.search .search-divider {
	display: none;
}

/* @section delete-form */
form.form-delete {
	margin-block-start: 2em;
}

form.form-delete button {
	margin: 0;
	padding: 0;
	border-block-end: 1px solid hsl(from var(--color-error) h s l / 30%);
	border-radius: 0;
	inline-size: auto;
	line-height: inherit;
	color: var(--color-error);
	background-color: transparent;
	background-image: none;
	box-shadow: none;
}

form.form-delete button:is(:hover, :focus, :active) {
	border-block-end-color: hsl(from var(--color-error) h s l / 60%);
}

/* @section guest-form */
.guest {
	position: relative;
	margin: 2em -10px;
	padding: 0 10px;
	border-style: solid;
	border-width: 19px 0 20px;
	border-image: inline('img/border.png') 19 19 20 fill repeat;
	font:
		1.5em/1.3 journal,
		fantasy;
	color: var(--color-black);
	background-color: var(--color-steel);
	box-shadow: 0 4px 15px hsl(from var(--color-black) h s l / 50%);
}

.guest legend {
	position: absolute;
	inset-inline-start: -1000em;
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
}

.guest + h1 {
	margin-block-start: 0.5em;
}

.guest .guest-input p {
	margin-block-end: 0.5em;
}

.guest .guest-input p.guest-input-small {
	margin-block: 2em 0.75em;
	font-size: 0.8em;
	line-height: 1.1;
}

.guest .guest-submit::after {
	content: '';
	display: none;
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	inline-size: 52px;
	block-size: 52px;
	color: var(--color-black);
	background-image: inline('img/stamp.png');
	background-repeat: no-repeat;
}

.guest .guest-submit p {
	display: none;
	margin-block-end: 0;
	border-block-end: 1px dashed hsl(from var(--color-black) h s l / 50%);
}

.guest .guest-submit div {
	margin-block-start: 1em;
	text-align: center;
}

.guest input {
	accent-color: hsl(from var(--color-steel) h s calc(l - 50));
	color: var(--color-black);
	background-color: transparent;
	font:
		1em/1.3 journal,
		fantasy;
	inline-size: 95%;
	border: 0;
	border-block-end: 1px dashed hsl(from var(--color-black) h s l / 50%);
}

.guest button {
	background-color: hsl(from var(--color-steel) h s calc(l - 50));
	background-image: none;
	font-size: 0.7em;
}

.guest button:is(:hover, :focus) {
	box-shadow: 0 0 0.5em hsl(from var(--color-steel) h s l / 50%);
}

.guest a:is(:link, :visited) {
	color: var(--color-black);
	border-block-end: 1px dotted hsl(from var(--color-black) h s l / 25%);
}

.guest a:is(:hover, :focus, :active) {
	color: var(--color-black);
	border: 0;
}

/* @section sizes */
.sizes {
	display: flex;
}

.sizes input {
	display: none;
}

.sizes span {
	display: block;
	padding: 0.25em 0.75em;
	border: 1px solid var(--color-beige-shaded);
	font-weight: normal;
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
	cursor: pointer;
}

.sizes label:first-child span {
	padding-inline-start: 1em;
	border-radius: 1em 0 0 1em;
}

.sizes label:last-child span {
	padding-inline-end: 1em;
	border-radius: 0 1em 1em 0;
}

.sizes input:checked + span {
	font-weight: bold;
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-shaded),
		var(--color-beige-tinted)
	);
}

/* @section suggestions */
.suggestions {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1ch;
	margin-block-start: 1em;
	font-size: 0.75em;
}

.suggestions button {
	position: relative;
	display: inline-block;
	margin: 0 0 0 16px;
	padding: 0.25em 1em 0.25em 0.75em;
	border: 1px solid var(--color-beige-shaded);
	border-radius: 0 1em 1em 0;
	inline-size: auto;
	line-height: inherit;
	white-space: nowrap;
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
	box-shadow: none;
}

.suggestions button::before {
	content: '';
	position: absolute;
	inset-block-start: -1px;
	inset-inline-start: -16px;
	inline-size: 20px;
	block-size: 24px;
	background-image: inline('assets/img/tag.png');
	background-repeat: no-repeat;
	background-size: contain;
}

/* @section current-upload */
.current-upload {
	display: block;
	margin: 0 0 0.5em;
}

/* @section logout */
#logout-button {
	display: block;
	margin-block-start: 16px;
	margin-inline-start: auto;
}

/* @section password-strength */
.password-strength {
	display: none;
	inline-size: 0;
	block-size: 2px;
	overflow: hidden;
	transition: 0.2s linear all;
}

.password-strength.password-strength-weak {
	background-color: var(--color-error);
}

.password-strength.password-strength-medium {
	background-color: var(--color-highlight);
}

.password-strength.password-strength-strong {
	background-color: var(--color-accent);
}

.password-strength-text {
	display: inline-block;
	margin-inline-start: 0.5em;
	padding: 0 1em;
	border-radius: 1em;
	font-size: 0.625rem;
	color: var(--color-white);
	vertical-align: middle;
}

.password-strength-text.password-strength-text-weak {
	background-color: var(--color-error);
}

.password-strength-text.password-strength-text-medium {
	background-color: var(--color-highlight);
}

.password-strength-text.password-strength-text-strong {
	background-color: var(--color-accent);
}

/* @section small-screen */
@media screen and (width <= 720px) {
	.button-icon.button-random {
		position: absolute;
		inset-block-start: -50px;
		inset-inline-end: 10px;
		z-index: 3;
		inline-size: 40px;
		block-size: 40px;
		margin-block-end: 0;
		padding-inline: 0;
		border-radius: 0;
		background-color: transparent;
		background-image: inline('img/random.svg');
		background-repeat: no-repeat;
		background-position: 25% 50%;
		background-size: contain;
		text-indent: -1000em;
		overflow: hidden;
	}

	.button-icon.button-random:is(:hover, :focus) {
		box-shadow: none;
	}

	.button-icon.button-random::before {
		display: none;
	}
}

/* @section large-screen */
@media screen and (width >= 721px) {
	button,
	.button:is(:link, :visited, :hover, :focus, :active) {
		inline-size: auto;
		margin-block-end: 0;
	}

	.guest {
		display: flex;
		margin-inline: auto;
		max-inline-size: 460px;
		min-block-size: 300px;
		border-inline-start-width: 19px;
		border-inline-end-width: 19px;
		transform: rotate(-2deg) translateZ(0);
	}

	.guest + h1 {
		margin-block-start: 3em;
	}

	.guest > div {
		inline-size: 50%;
		box-sizing: border-box;
	}

	.guest .guest-input {
		border-inline-end: 1px solid
			hsl(from var(--color-steel) h s calc(l - 50));
	}

	.guest .guest-submit {
		padding-block-start: 174px;
		padding-inline-start: 4%;
	}

	.guest .guest-submit::after,
	.guest .guest-submit p {
		display: block;
	}

	.guest input {
		inline-size: 90%;
	}
}
