/**
 * Toolbar
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* toolbar */
.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-inline-end: 0.5rem;
	border: 1px solid currentcolor;
	border-block-end: 0;
	color: var(--color-gray);
	background-color: var(--color-white);
}

.toolbar > * {
	display: flex;
	align-items: center;
}

/* toolbar links */
.toolbar a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
	fill: currentcolor;
	border-block-end: none;
}

/* toolbar buttons */
.button-toolbar,
.button-toolbar:is(:link, :visited, :hover, :focus, :active) {
	margin: 0;
	padding: 0.5rem;
	border: 0;
	inline-size: 2rem;
	block-size: 2rem;
	overflow: hidden;
	font-size: 0.75em;
	line-height: inherit;
	text-align: center;
	color: inherit;
	background-color: transparent;
	background-image: none;
	box-sizing: border-box;
	box-shadow: none;
	transition: color 0.2s linear;
}

@media (hover: hover) {
	.toolbar a:hover,
	.button-toolbar:hover {
		color: var(--color-black);
	}
}

@media print {
	.toolbar {
		display: none;
	}
}
