/**
 * Tables
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section table-defaults */
table {
	margin: 0 0 1em;
	border-block-start: 1px solid var(--color-beige-shaded);
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 0.75em;
	inline-size: 100%;
}

caption {
	display: none;
}

thead th {
	padding: 2px 1em;
	border-inline-start: 1px solid var(--color-beige-shaded);
	border-inline-end: 1px solid var(--color-beige-shaded);
	vertical-align: bottom;
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

tbody th {
	padding: 2px 1em;
	border: 1px solid var(--color-beige-shaded);
	text-align: start;
	vertical-align: top;
}

tbody td {
	padding: 2px 1em;
	border: 1px solid var(--color-beige-shaded);
	text-align: center;
	vertical-align: top;
}

tbody tr:nth-child(even) th,
tbody tr:nth-child(even) td {
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

/* @section scores */
.score-table {
	inline-size: 100%;
	font-variant-numeric: tabular-nums;
}

.score-table th,
.score-table td,
.score-table thead th,
.score-table tbody td {
	padding: 2px;
}

.score-table thead th[colspan] {
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		#d8d3b9
	);
}

.score-table thead tr:nth-child(2) th {
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		#d8d3b9,
		var(--color-beige-shaded)
	);
}

.score-table .cancelled {
	/* stylelint-disable-next-line declaration-no-important */
	color: hsl(from var(--color-gray) h s l / 40%) !important;
}

.score-table svg {
	inline-size: 0.5em;
	block-size: auto;
	vertical-align: 0.125em;
	margin-inline-start: 0.5ch;
	fill: currentcolor;
	cursor: auto;
}

/* @section small-screen */
@media screen and (width <= 400px) {
	.score-table {
		/* stylelint-disable-next-line declaration-no-important */
		font-size: 1em !important;
	}

	.score-table colgroup,
	.score-table thead,
	.score-table td {
		display: none;
	}

	.score-table td:first-child,
	.score-table td:last-child {
		display: table-cell;
		font-weight: normal;
	}
}

@media screen and (width >= 721px) {
	table {
		inline-size: auto;
		min-inline-size: 50%;
		font-size: 1em;
	}
}
