/**
 * Print
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

@media print {
	/* @section page-layout */
	@page {
		size: a4 portrait;
		margin: 1cm 1.5cm 1cm 2cm;
		orphans: 4;
		widows: 2;
	}

	@bottom-center {
		content: counter(page);
	}

	body {
		inline-size: 100%;
		max-inline-size: none;
		margin: 0;
		padding: 0;
		color: #000;
		background-color: #fff;
		background-image: none;
	}

	/* @section page-breaks */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table tr,
	.member-list li {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
	}

	/* @section images */
	img {
		/* stylelint-disable-next-line declaration-no-important */
		max-inline-size: 100% !important; /* prevents images from being cut when they are larger than page size */
	}

	/* @section links */
	a[href] {
		color: var(--color-link);
		background-color: transparent;
		background-image: none;
	}

	/* @section hidden-elements */
	nav,
	form,
	button,
	.button {
		/* stylelint-disable-next-line declaration-no-important */
		display: none !important;
	}

	/* @section customizations */
	.header {
		color: #000;
		background-color: transparent;
		background-image: none;
		min-block-size: 1cm;
		margin: 0.2cm;
		text-align: end;
	}

	.header img {
		inline-size: 1cm;
		block-size: auto;
	}

	header,
	main {
		padding: 0;
	}

	h1,
	h2 strong {
		text-shadow: none;
	}

	.member-list .award.king,
	.member-list .award.cup {
		color: #000;
		background-color: transparent;
		background-image: none;
		padding: 1px 8px;
		text-shadow: none;
		box-shadow: none;
	}

	.member-list .award::before {
		display: none;
	}
}
