/**
 * Typography
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section main */
html {
	font-family: var(--font-text);
	font-size: 1em;
	line-height: 1.375;
	quotes: '»' '«' '›' '‹';
}

/* @section abbreviations */
abbr[title] {
	text-decoration: none;
	cursor: help;
}

/* @section headlines */
h1 {
	margin-block: 0 0.5em;
	font-weight: normal;
	font-family: var(--font-headline);
	text-shadow: 0 4px 0 hsl(from var(--color-gray) h s l / 20%);
	text-wrap: balance;
	line-height: 1.2;
}

h1 ~ h1 {
	margin-block-start: 3em;
}

.header h1 {
	margin-block-end: 0;
	padding: 30px 0;
	color: var(--color-white);
	font-size: 2.5em;
	text-align: center;
	text-transform: uppercase;
	text-shadow: 0 4px 0 hsl(from var(--color-black) h s l / 60%);
}

.header h1 strong {
	display: block;
	color: var(--color-highlight);
	font-size: 0.6667em;
	font-weight: normal;
	text-transform: none;
}

.header h1::after {
	content: '';
	position: absolute;
	inset-block-end: -16px;
	inset-inline-start: 50%;
	z-index: 2;
	margin-inline-start: -16px;
	border-width: 16px 16px 0;
	border-style: solid;
	border-color: var(--color-gradient-end) transparent transparent transparent;
	inline-size: 0;
	block-size: 0;
	color: var(--color-gray);
}

h2 {
	margin: 1em 0 0.6667em;
	font-weight: normal;
	font-family: var(--font-headline);
	text-transform: uppercase;
	text-wrap: balance;
	line-height: 1;
}

h2 strong {
	display: block;
	margin: 0.125em 0 0;
	font-size: 1.3333em;
	font-weight: normal;
	text-transform: none;
	text-shadow: 0 4px 0 hsl(from var(--color-gray) h s l / 10%);
	color: #060;
}

h2 .small {
	display: block;
	font-size: 0.6667em;
}

h3 {
	margin: 1.5em 0 1em;
	font: bold 1em/1 var(--font-text);
	color: var(--color-gray);
}

h4 {
	margin: 1.5em 0 1em;
	font: bold 1em/1 var(--font-text);
	color: hsl(from var(--color-gray) h s calc(l + 33));
}

h5,
h6 {
	margin: 1.3333em 0;
	color: hsl(from var(--color-gray) h s calc(l + 33));
	font: bold 0.75em/1 var(--font-text);
	text-transform: uppercase;
}

:is(h1, h2, h3, h4, h5, h6) abbr[title] {
	border-block-end: 0;
}

/* @section paragraphs */
p {
	margin: 0 0 1em;
	text-wrap: pretty;
}

p.intro {
	margin-block: 1em 2em;
	text-align: center;
}

p.intro img {
	max-inline-size: 550px;
	inline-size: 100%;
	block-size: auto;
	box-shadow: 0 0 15px hsl(from var(--color-gray) h s l / 50%);
	transform: rotate(-2deg) translateZ(0);
}

p.signature {
	font:
		2.5em/1.3 journal,
		fantasy;
}

address {
	margin: 0 0 1em;
}

/* @section quotations */
blockquote {
	margin: 0 0 1em;
	padding: 0 1em;
	font-style: italic;
}

/* @section links */
a:is(:link, :visited, :hover, :focus, :active) {
	position: relative;
	text-rendering: auto;
	text-decoration: none;
}

a:is(:link, :visited) {
	color: var(--color-link);
	border-block-end: 1px solid hsl(from var(--color-link) h s l / 30%);
}

a:is(:hover, :focus, :active) {
	color: var(--color-link-active);
	border-block-end: 1px solid hsl(from var(--color-link) h s l / 60%);
}

a:active {
	inset-block-start: 1px;
}

/* @section code */
code {
	color: var(--color-black);
	background-color: hsl(from var(--color-beige-light) h s calc(l - 50));
	padding: 2px 3px;
	border-radius: 3px;
	text-shadow: 0 1px 1px hsl(from var(--color-white) h s l / 75%);
}

pre {
	margin: 0 0 2em;
	padding: 1em;
	border-radius: 3px;
	text-shadow: 0 1px 1px hsl(from var(--color-white) h s l / 75%);
	overflow: auto;
	color: var(--color-black);
	background-color: hsl(from var(--color-white) h s l / 50%);
	tab-size: 4;
}

/* @section alternate-typography */
.alternate {
	font:
		italic bold 1em 'Warnock Pro',
		'Goudy Old Style',
		palatino,
		'Book Antiqua',
		georgia,
		serif;
}

/* @section large-screen */
@media screen and (width >= 721px) {
	.header h1 {
		padding-block: 60px;
		font-size: 3.75em;
	}
}
