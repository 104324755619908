/**
 * Helpers
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section selections */
::selection {
	color: var(--color-gray);
	background-color: var(--color-beige-light);
}

/* @section classes */
.offscreen-hidden {
	position: absolute;
	inset-inline-start: -1000em;
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
}

.visually-hidden {
	visibility: hidden !important;
}

.hidden {
	display: none !important;
}

.updated {
	font-style: italic;
}
