/**
 * Details
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

details {
	margin: 1em 0;
}

details summary {
	font-weight: bold;
	cursor: pointer;
}

details[open] summary {
	margin-block-end: 0.5em;
}
