/**
 * Games
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

@keyframes fade {
	from {
		background-color: hsl(from var(--color-black) h s l / 0%);
	}

	to {
		background-color: hsl(from var(--color-black) h s l / 80%);
	}
}

@keyframes comeup {
	from {
		transform: translateY(5em);
		opacity: 0;
	}

	to {
		transform: translate(0);
		opacity: 1;
	}
}

/* @section games */
.games-group {
	padding: 0.25em 0 0.5em;
}

.games-group:nth-child(even) {
	padding: 0.25em 0 0.5em;
}

.games-group .count {
	position: absolute;
	inset-block-start: 3px;
	inset-inline-end: 0;
	padding: 0 0.75em;
	font: 12px/1.5 var(--font-text);
	color: var(--color-white);
	background-color: var(--color-gradient-start);
	background-image: linear-gradient(
		to bottom,
		var(--color-gradient-start),
		var(--color-gradient-end)
	);
	border-radius: 1em;
	user-select: none;
}

.games-group .category {
	position: relative;
	padding: 0 40px 0 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pin-image {
	inline-size: 95px;
	block-size: 95px;
	cursor: pointer;
}

#lightbox {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 4;
	padding: 4em 2em 2em;
	inline-size: 100%;
	block-size: 100%;
	text-align: center;
	color: var(--color-white);
	background-color: hsl(from var(--color-black) h s l / 80%);
	box-sizing: border-box;
	animation: 0.2s fade linear;
	transform: translateZ(0);
}

#lightbox .pin-setup {
	pointer-events: none;
}

#lightbox svg {
	inline-size: 100%;
	block-size: auto;
	max-inline-size: 90vmin;
	max-block-size: 90vmin;
	animation: 150ms comeup ease 0.2s;
	animation-fill-mode: backwards;
}

/* @section large-screen */
@media screen and (width >= 721px) {
	.games-group {
		display: inline-block;
		padding: 0 2% 1em 0;
		inline-size: 48%;
		vertical-align: top;
	}

	.games-group:nth-child(even) {
		margin: 0 0 0 -1ex;
		padding: 0 0 1em 2%;
	}

	.games-group .count {
		padding-inline: 1em;
	}

	.pin-image {
		inline-size: 100px;
		block-size: 100px;
	}

	#lightbox {
		padding-block-start: 2em;
	}
}

/* @section meta-data */
.game-meta {
	display: flex;
	flex-direction: column-reverse;
}

/* @section rating */
.rating {
	display: inline-flex;
	align-items: center;
	margin-block-end: 1em;
	block-size: 24px;
}

.rating form {
	display: none;
	margin-block-end: 0;
}

.rating.with-form:hover #current-rating {
	display: none;
}

.rating:hover form {
	display: inline-flex;
}

.rating form input[type='radio'] {
	position: absolute;
	inset-block-start: -100em;
	inset-inline-start: -100em;
}

.rating form label {
	position: relative;
	inline-size: 24px;
	block-size: 24px;
	cursor: pointer;
}

.rating form label svg {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
}

.rating form label svg + svg {
	display: none;
}

.rating form:hover label svg + svg {
	display: block;
}

.rating form:hover label:hover ~ label svg + svg {
	display: none;
}

#rating-text {
	margin-inline-start: 1ex;
	pointer-events: none;
}

.rating-stars {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

/* @section large-screen */
@media screen and (width >= 721px) {
	.game-meta {
		flex-direction: row;
		justify-content: space-between;
	}

	.rating {
		margin-block-end: 3em;
		min-inline-size: 12em;
	}
}
