/**
 * Fonts
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section webfont */
@font-face {
	font-family: 'Museo Slab';
	font-display: swap;
	src:
		local('Museo Slab'),
		local('Museo-Slab'),
		inline('fonts/museo.woff2') format('woff2'),
		resolve('fonts/museo.woff') format('woff');
}

@font-face {
	font-family: journal;
	font-display: swap;
	src:
		local('Journal'),
		local('journal'),
		inline('fonts/journal.woff2') format('woff2'),
		resolve('fonts/journal.woff') format('woff');
}
