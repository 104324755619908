/**
 * Visible items after loading all CSS
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

body {
	background-image: inline('assets/img/background.jpg');
}

.header h1,
main {
	display: block;
}

body::after {
	display: none;
}
