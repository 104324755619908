/**
 * Flip Card
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section flip-toggle */
.flip-toggle {
	display: none;
	padding-inline-start: 0;
	list-style-type: none;
}

.flip-toggle li {
	margin-block-end: 0;
}

.flip-toggle a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	inset-block-start: 0;
	padding: 0.25em 0.75em;
	border: 1px solid var(--color-beige-shaded);
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

.flip-toggle li:first-child a:is(:link, :visited, :hover, :focus, :active) {
	padding-inline-start: 1em;
	border-radius: 1em 0 0 1em;
}

.flip-toggle li:last-child a:is(:link, :visited, :hover, :focus, :active) {
	padding-inline-end: 1em;
	border-radius: 0 1em 1em 0;
}

.flip-toggle .current a:is(:link, :visited, :hover, :focus, :active) {
	font-weight: bold;
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-shaded),
		var(--color-beige-tinted)
	);
	pointer-events: none;
}

.js .flip-toggle {
	display: flex;
	justify-content: center;
	user-select: none;
}

/* @section flip-card */
.js .flip-card.flipped > div:first-child,
.js .flip-card > div:last-child {
	display: none;
}

.js .flip-card > div:first-child,
.js .flip-card.flipped > div:last-child {
	display: block;
}

/* @section small-screen */
@media screen and (width <= 400px) {
	.js .flip-wrapper {
		position: relative;
		perspective: 964px;
		min-block-size: 520px;
	}

	.js .flip-card {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		inline-size: 100%;
		block-size: 100%;
		transform-style: preserve-3d;
		transform-origin: 100% 50%;
		transition: transform 0.4s ease;
	}

	.js .flip-card.flipped {
		transform: translateX(-100%) rotateY(-180deg);
	}

	.js .flip-card > div {
		position: absolute;
		z-index: 1;
		inline-size: 100%;
		block-size: 100%;
		backface-visibility: hidden;
	}

	.js .flip-card.flipped > div:first-child {
		display: block;
	}

	.js .flip-card > div:last-child {
		display: block;
		transform: rotateY(180deg);
	}
}
