/**
 * Lists
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section unordered-lists */
ul {
	margin: 0 0 1em;
	padding: 0 0 0 2.5em;
	list-style-type: disc;
}

ul li {
	margin: 0 0 1em;
}

/* @section ordered-lists */
ol {
	margin: 0 0 1em;
	padding: 0 0 0 2.5em;
	list-style-type: decimal;
}

ol li {
	margin: 0 0 1em;
}

/* @section tag-lists */
.tag-list {
	display: flex;
	flex-wrap: wrap;
	margin-block-end: 2em;
	padding-inline-start: 0;
	list-style-type: none;
}

.tag-list li {
	margin: 0 1em 1em 0;
	font-size: 0.75em;
}

.tag-list a[rel*='tag']:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	margin-inline-start: 16px;
	padding: 0.25em 1em 0.25em 0.75em;
	border: 1px solid var(--color-beige-shaded);
	border-radius: 0 1em 1em 0;
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

.tag-list a[rel*='tag']::before {
	content: '';
	position: absolute;
	inset-block-start: -1px;
	inset-inline-start: -16px;
	inline-size: 20px;
	block-size: 24px;
	background-image: inline('assets/img/tag.png');
	background-repeat: no-repeat;
	background-size: contain;
}

/* @section item-lists */
.item-list {
	margin-block-start: 1em;
	padding-inline-start: 0;
	list-style-type: none;
}

.item-list a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	box-sizing: border-box;
	padding: 8px 30px 8px 10px;
	inline-size: 100%;
	font-weight: bold;
	text-shadow: 0 2px 0 var(--color-beige-shaded-active);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.item-list a:is(:link, :visited) {
	border: 1px solid var(--color-beige-shaded);
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted),
		var(--color-beige-shaded)
	);
}

.item-list a:is(:hover, :focus) {
	border: 1px solid var(--color-beige-shaded-active);
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-tinted-active),
		var(--color-beige-shaded-active)
	);
}

.item-list a:active {
	inset-block-start: 0;
	border: 1px solid var(--color-beige-shaded-active);
	color: var(--color-gray);
	background-color: var(--color-beige);
	background-image: linear-gradient(
		to bottom,
		var(--color-beige-shaded-active),
		var(--color-beige-tinted-active)
	);
}

.item-list a::after {
	content: '';
	position: absolute;
	inset-block-start: 50%;
	inset-inline-end: 15px;
	margin: -8px 0 0;
	inline-size: 9px;
	block-size: 16px;
	color: var(--color-gray);
	background-color: transparent;
	background-image: inline('assets/img/next.svg');
	background-repeat: no-repeat;
	background-size: 9px 16px;
}

.item-list li {
	display: inline;
}

.item-list
	li:is(.first, :first-child)
	a:is(:link, :visited, :hover, :focus, :active) {
	border-start-start-radius: 10px;
	border-start-end-radius: 10px;
}

.item-list
	li:is(.last, :last-child)
	a:is(:link, :visited, :hover, :focus, :active) {
	border-end-start-radius: 10px;
	border-end-end-radius: 10px;
}

.item-list strong {
	font-weight: normal;
}

.item-list img {
	vertical-align: -4px;
	border-radius: 100%;
}

/* @section member-lists */
.member-list {
	padding-inline-start: 0;
	list-style-type: none;
}

.member-list > li {
	margin-block: 3em;
	text-align: center;
}

.member-list p {
	margin-block-end: 0.5em;
}

.member-list ul {
	margin-block-end: 0;
	padding-inline-start: 0;
	list-style-type: none;
}

.member-list h2 > img {
	display: block;
	margin: 0 auto 0.5em;
	inline-size: 134px;
	border: 3px solid var(--color-beige);
}

.member-list .award {
	position: relative;
	display: inline-block;
	margin: 0 1em 0.5em 0;
	padding: 1px 8px 1px 20px;
	font-size: 0.75em;
	border-radius: 0.5em;
	text-shadow: 0 1px 0 hsl(from var(--color-white) h s l / 40%);
	box-shadow: inset 1px 1px 0 hsl(from var(--color-white) h s l / 70%);
}

.member-list .award::before {
	content: '';
	position: absolute;
	inset-inline-start: 5px;
}

.member-list .award.king {
	border: 1px solid #ffd06a;
	color: var(--color-gray);
	background-image: linear-gradient(to bottom, #ff9, #ffd06a);
}

.member-list .award.king::before {
	inset-block-start: 5px;
	inline-size: 10px;
	block-size: 8px;
	color: var(--color-gray);
	background-image: inline('assets/img/crown.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px 8px;
}

.member-list .award.cup {
	border: 1px solid #ccc;
	color: var(--color-gray);
	background-image: linear-gradient(to bottom, #eee, #ccc);
}

.member-list .award.cup::before {
	inset-block-start: 3px;
	inline-size: 10px;
	block-size: 11px;
	color: var(--color-gray);
	background-image: inline('assets/img/award.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px 11px;
}

/* @section large-screen */
@media screen and (width >= 721px) {
	.item-list a:is(:link, :visited, :hover, :focus, :active) {
		margin-block-end: 0.5em;
		padding-inline: 25px 40px;
	}

	.item-list a::after {
		inset-inline-end: 20px;
	}

	.item-list a:is(:link, :visited, :hover, :focus, :active),
	.item-list li:first-child a:is(:link, :visited, :hover, :focus, :active),
	.item-list li:last-child a:is(:link, :visited, :hover, :focus, :active),
	.item-list
		li:first-child:last-child
		a:is(:link, :visited, :hover, :focus, :active) {
		border-radius: 20px;
	}

	.item-list .separated a:is(:link, :visited, :hover, :focus, :active) {
		margin-block-end: 1em;
	}

	.member-list > li {
		position: relative;
		min-block-size: 134px;
		padding-inline-start: 154px;
		text-align: start;
	}

	.member-list h2 > img {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		margin: 0;
		border: 0;
	}
}
