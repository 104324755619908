/**
 * Messages
 * @project Kegelclub Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* @section form responses */
.message {
	padding: 10px;
	border-radius: 10px;
	color: var(--color-white);
	background-color: #060;
	background-image: linear-gradient(to bottom, #060, #030);
	text-align: center;
	text-shadow: 0 4px 0 hsl(from var(--color-black) h s l / 40%);
	box-shadow: 0 1px 3px hsl(from var(--color-black) h s l / 30%);
}

.message a:is(:link, :visited, :hover, :focus, :active) {
	border-block-end: 1px solid hsl(from var(--color-white) h s l / 60%);
	color: var(--color-white);
}

/* @section notifications */
.notify {
	padding: 0.5em 1em;
	border-block-end: 1px solid hsl(from #fc0 h s calc(l - 25));
	font: 0.75em/1.3 var(--font-text);
	text-align: center;
	color: var(--color-black);
	background-color: hsl(from var(--color-beige-light) h s calc(l - 25));
	background-image: linear-gradient(to bottom, #fe0, #fc0);
}

.notify.notify-animated {
	display: none;
}

.notify a:is(:link, :visited, :hover, :focus, :active) {
	inset-block-start: 0;
	border-block-end: 0;
	font-weight: bold;
	color: var(--color-black);
}

.notify a:is(:link, :visited) {
	text-decoration: underline;
}

.notify a:is(:hover, :focus, :active) {
	text-decoration: none;
}

.notify button,
.notify button:is(:hover, :focus, :active) {
	margin: 0.5em 0 0;
	padding: 0.25em 1em;
	line-height: 1;
	color: var(--color-white);
	background-color: var(--color-gray);
	background-image: linear-gradient(
		to bottom,
		hsl(from var(--color-gray) h s calc(l + 30)),
		hsl(from var(--color-gray) h s calc(l - 80))
	);
	box-shadow: none;
}

.notify button:first-of-type {
	font-weight: bold;
}

/* @section large-screen */
@media screen and (width >= 721px) {
	.notify button,
	.notify button:is(:hover, :focus, :active) {
		margin-block-start: 0;
		margin-inline-start: 1em;
	}
}
